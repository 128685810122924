import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useEffect } from "react";
import * as GTM from "../lib/googleTagManager";
interface Props {
  visible: boolean;
  onClose: () => void;
  fullPageWidth?: boolean;
  title?: string;
  isOpenedByTrigger?: boolean;
  fullScreen?: boolean;
  className?: string;
  children: React.ReactNode;
}
export const Modal: React.FC<Props> = ({
  children,
  visible,
  onClose,
  fullPageWidth,
  className,
  title,
  isOpenedByTrigger,
  fullScreen = false
}) => {
  useEffect(() => {
    if (visible) {
      GTM.dispatchModalViewed(isOpenedByTrigger ? `auto-trigger-${title}` : title);
    }
    return () => {
      GTM.clearModalDataLayer();
    };
  }, [isOpenedByTrigger, title, visible]);
  return <Transition.Root show={!!visible} as={Fragment} data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <div className={clsx("flex min-h-screen items-center justify-center text-center sm:block ", {
        "px-4 pt-4 pb-20 sm:p-0": !fullScreen
      })}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            <Dialog.Overlay className="fixed inset-0 bg-plum-40 transition-opacity" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            <div className={clsx("relative inline-block transform overflow-hidden bg-white text-left align-bottom shadow-lg transition-all sm:my-8 sm:w-full sm:align-middle", {
            "sm:max-w-2xl": !fullPageWidth,
            "sm:max-w-7xl": fullPageWidth,
            "rounded-lg py-6 px-4 sm:py-16": !fullScreen,
            "h-screen w-screen md:h-auto md:rounded-lg": fullScreen
          }, className)}>
              <div className="absolute top-0 right-0 pt-4 pr-4 z-50">
                <button type="button" className="rounded-md bg-white text-plum-64 hover:text-plum focus:outline-none" onClick={onClose}>
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="Modal.tsx">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" data-sentry-element="path" data-sentry-source-file="Modal.tsx" />
                  </svg>
                </button>
              </div>
              <div className={clsx({
              "h-full overflow-scroll": fullScreen
            })}>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>;
};